import Peer from 'peerjs';
import Car from './Car';
import CarController from './CarController';
import Game, { Command } from "./Game";
import { InputController } from './InputController';

function getTimeout()
{
    return 0;
    // return 150 + 50 * Math.random(); // for test
}

export class GameServer extends Game
{
    private peer = new Peer();
    private coonections: Peer.DataConnection[] = [];
    private clients: {[peer: string]: string} = {};
    protected carControllers: {[id: string]: CarController} = {};
    protected myCarController = new CarController(this.myCar);
    onSignal?: (data: string) => void = undefined;

    constructor(ctx: CanvasRenderingContext2D)
    {
        super(ctx);
        console.log("server", this.id);
        
        let controller = new InputController(() => {
            this.myCarController.setControlsState(controller.state)
        }); 

        this.myCar.onChangeState = () => this.onChangeCarState(this.id, this.myCarController.car);
        this.carControllers[this.id] = this.myCarController;

        this.initPeer();
    }

    initPeer()
    {
        this.peer.on('open', id => {
            if (this.onSignal instanceof Function)
                this.onSignal(id);
        });

        this.peer.on('error', err => console.log('peer error', err));
        this.peer.on('close', () => console.log('peer close'));

        this.peer.on('connection', (conn) => 
        {
            this.coonections.push(conn);

            conn.on('error', err => this.onErrorConnection(conn, err))
            conn.on('open', () => this.onOpenConnection());
            conn.on('close', () => this.onCloseConnection(conn));
            conn.on('data', data => this.onData(conn, data));
        }); 
    }

    onData(conn: Peer.DataConnection, data:string)
    {
        let cmd = JSON.parse(data) as Command;
        this.onCommand(cmd);
        
        if (cmd.name === "connect") 
        {
            this.send({id: this.id, name: "synctime", data: {connect_time: cmd.data.time, timestamp: this.timestamp}}, conn);
            this.clients[conn.peer] = cmd.id;

            let controller = new CarController(this.cars[cmd.id]);
            this.carControllers[cmd.id] = controller;
            controller.car.onChangeState = () => this.onChangeCarState(cmd.id, controller.car);
        }
        else if (cmd.name == "disconnect")
        {
            delete this.carControllers[cmd.id];
        }
        else if (cmd.name === "input")
        {
            let controller = this.carControllers[cmd.id];
            controller.setControlsState(cmd.data);
            return;
        } 

        setTimeout(() => this.coonections.forEach(c => c.send(data)), getTimeout());
    }

    onOpenConnection()
    {
        for (let [id, car] of Object.entries(this.cars))
        {
            this.send({id, name: "connect", data: {
                time: 0,
                color: car.color,
                name: car.name,
                state: car.state,
            }});
        }
    }

    onCloseConnection(connection: Peer.DataConnection)
    {
        let index = this.coonections.indexOf(connection);
        if (index > -1)
        {
            this.coonections.splice(index, 1);
        }

        let carId = this.clients[connection.peer];
        if (carId)
        {
            delete this.cars[carId];
            delete this.clients[connection.peer];
            this.send({id: carId, name: "disconnect"});
        }
    }

    onErrorConnection(connection: Peer.DataConnection, err:any)
    {
        console.log('connection error:', err);
        this.onCloseConnection(connection);
    }

    onChangeCarState(id: string, car:Car)
    {
        this.send({id, name: "cstate", data: {timestamp: this.timestamp, state: car.state}});
    }

    setName(name:string)
    {
        super.setName(name);
        this.send({id: this.id, name: "name", data: {name}});
    }

    protected send(data: Command, connection: Peer.DataConnection | "all" = "all")
    {
        let dataToSend = JSON.stringify(data);
        if (connection === "all")
            setTimeout(() => this.coonections.forEach(c => c.send(dataToSend)), getTimeout());
        else
            setTimeout(() => connection.send(dataToSend), getTimeout());
    }

    override tick(): void 
    {
        super.tick();
        this.processControllers();
        this.processCollisions();
    }

    private processControllers()
    {
        let controllers = Object.values(this.carControllers);
        for (let controller of controllers) 
        {
            controller.tick();
        }
    }

    private processCollisions() 
    {
        let cars = Object.values(this.cars);
        for (let car of cars) 
        {
            for (let car2 of cars) 
            {
                for (let ball of car2.balls) 
                {
                    if (ball.intersect(car)) 
                    {
                        if (car !== car2) 
                        {
                            car.addVolume(-ball.getVolume());
                            car2.addVolume(2 * ball.getVolume());
                        }
                        else 
                        {
                            car2.addVolume(ball.getVolume());
                        }
                        car2.removeBall(ball);
                    }
                }
            }

            
        }
    }
}