import BaseObject from "./BaseObject";
import Car from "./Car";

export default class Eball extends BaseObject
{

    constructor(private car: Car, speed: number, value: number) 
    {
        let distance = value + speed + car.value + car.speed;
        let x = car.x + distance * Math.cos(car.angle);
        let y = car.y + distance * Math.sin(car.angle);
        super(car.map, x, y, value, car.angle, car.speed + speed);
    }

    draw()
    {
        let x = this.x + this.map.x;
        let y = this.y + this.map.y;

        let ctx = this.car.ctx;
        ctx.beginPath();
        ctx.strokeStyle = this.car.color;
        ctx.arc(x, y, this.value, 0, 2*Math.PI);
        ctx.stroke();
    }
}