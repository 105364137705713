import Car, { ICarState } from "./Car"
import GameMap from "./GameMap";
import { IControlsState } from "./InputController";

export type Command = {id: string} & (
    {name: "connect", data: {time:number, color:string, name:string, state: ICarState}} |
    {name: "disconnect"} | 
    {name: "name", data: {name:string}} |
    {name: "input", data: IControlsState} |
    {name: "eball", data: {x:number, y:number}} |
    {name: "cstate", data: {timestamp: number, state: ICarState}} |
    {name: "latency", data: {client: number, server: number, diff: number}} |
    {name: "synctime", data: {connect_time: number, timestamp: number}}
);

function generateColor()
{
    return "#" + [
        ...Array.apply(null, Array(1+2*Math.random()|0)).map(i=>(192+Math.random()*36|0).toString(16)),
        '00', '00'
    ].slice(0,3).sort(()=>-0.5+Math.random()).join('');
}

export default class Game 
{
    protected id = (Math.random() + 1).toString(36).substring(6);
    protected start = Date.now();
    protected map = new GameMap(this.ctx, 10000);
    protected cars: {[id: string]: Car} = {};
    protected myCar = new Car(this.map, this.ctx, generateColor(), window.localStorage.getItem("name") || "");
    protected bodies: Body[] = [];

    constructor(readonly ctx: CanvasRenderingContext2D)
    {
        this.myCar.setState({...this.myCar.state, 
            x: 100+(ctx.canvas.width-200)*Math.random(), 
            y: 100+(ctx.canvas.height-200)*Math.random(),
        });

        this.cars[this.id] = this.myCar;
    }

    protected send(data: Command) {};

    get name() {return this.myCar.name}
    get speed() {return this.myCar.speed}
    get timestamp() {return Date.now() - this.start}

    setName(name: string)
    {
        this.myCar.name = name;
        window.localStorage.setItem("name", name);
    }

    tick()
    {
        this.map.position(this.myCar.x, this.myCar.y);
        this.map.draw();

        for (let car of Object.values(this.cars))
        {
            car.tick();    
            car.draw();    
        }
    }

    onCommand(cmd: Command)
    {
        if (cmd.name === "connect") 
        {
            if (cmd.id === this.id)
                return;
            
            let car = new Car(this.map, this.ctx, cmd.data.color, cmd.data.name);
            car.setState(cmd.data.state);
            this.cars[cmd.id] = car;
        }
        else if (cmd.name === "disconnect")
        {
            delete this.cars[cmd.id];
        }
        else if (cmd.name === "name") 
        {
            let car = this.cars[cmd.id];
            car.name = cmd.data.name;
        }
    }

}

