import BaseObject from "./BaseObject";

const NET_STEP = 100;

export default class GameMap
{
    public x = 0;
    public y = 0;

    constructor(readonly ctx: CanvasRenderingContext2D, readonly size: number)
    {
    }

    position(x:number, y:number)
    {
        if (x < this.ctx.canvas.width / 2)
            this.x = 0;        
        else if (x > this.size - this.ctx.canvas.width / 2)
            this.x = this.ctx.canvas.width - this.size;
        else
            this.x = this.ctx.canvas.width / 2 - x;

        if (y < this.ctx.canvas.height / 2)    
            this.y = 0;
        else if (y > this.size - this.ctx.canvas.height / 2)
            this.y = this.ctx.canvas.height - this.size;
        else
            this.y = this.ctx.canvas.height / 2 - y;
    }
    
    draw() 
    {
        this.ctx.fillStyle = "red";
        this.ctx.strokeStyle = "red";
        
        for (let y = NET_STEP / 2; y < this.size; y+= NET_STEP)
        {
            for (let x = NET_STEP / 2; x < this.size; x+= NET_STEP)
            {
                this.ctx.fillRect(this.x + x, this.y + y, 3, 3);
            }
        }

        this.ctx.strokeRect(this.x, this.y, this.size, this.size);
    }
}