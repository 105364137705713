import Peer from 'peerjs';
import Game, { Command } from "./Game";
import { InputController } from './InputController';

function getTimeout()
{
    return 0;
    // return 150 + 50 * Math.random(); // for test
}

export class GameClient extends Game
{
    private peer = new Peer();
    private connection?: Peer.DataConnection = undefined;

    constructor(readonly ctx: CanvasRenderingContext2D, peedId:string) 
    {
        super(ctx);
        console.log("client", this.id);

        let controller = new InputController(() => {
            this.send({id: this.id, name:'input', data: controller.state})
        }); 
        
        this.peer.on('error', err => console.log('peer error:', err));
        this.peer.on('open', () => 
        {
            if (peedId) 
            {
                console.log("id:", peedId);
                this.connect(peedId);
            }
        });
    }

    connect(peerId:string)
    {
        let connection = this.peer.connect(peerId);
        connection.on('error', err => console.log('connection error:', err))
        connection.on('data', data => this.onData(data));
        connection.on('open', () => 
        {
            this.connection = connection;

            this.send({id: this.id, name: "latency", data: {client: Date.now(), server: 0, diff: 0}});

            this.send({id: this.id, name: "connect", data: {
                time: Date.now(),
                color: this.myCar.color,
                name: this.myCar.name,
                state: this.myCar.state,
            }});
        });
    }

    onData(data:string)
    {
        let cmd = JSON.parse(data) as Command;
        this.onCommand(cmd);
        
        if (cmd.name === "cstate") 
        {
            let state = cmd.data.state;
            console.log("cstate:", cmd.data.timestamp - this.timestamp);

            const latencySpan = document.getElementById("latency");
            if (latencySpan) 
                latencySpan.innerText = (cmd.data.timestamp - this.timestamp).toFixed();

            setTimeout(() => this.cars[cmd.id].setState(state), cmd.data.timestamp - this.timestamp);
        }
        else if (cmd.name === "synctime")
        {
            let now = Date.now();
            console.log("synctime:", now- cmd.data.connect_time);
            let extraDelay = (now - cmd.data.connect_time) / 2; // 250
            this.start = now - cmd.data.timestamp + extraDelay;
        }
    }

    setName(name:string)
    {
        super.setName(name);
        this.send({id: this.id, name: "name", data: {name}});
    }

    protected send(data: Command)
    {
        let dataToSend = JSON.stringify(data);
        setTimeout(() => this.connection?.send(dataToSend), getTimeout());
    }

}