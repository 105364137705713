import BaseObject from "./BaseObject";
import Car from "./Car";
import { IControlsState } from "./InputController";

export default class CarController
{
    private shotTime = 0;
    private shotInterval = 200;
    private ballSpeed = 0.6;
    private ballValue = 5;

    private state:IControlsState = {
        forward: false, 
        backward: false, 
        right: false,
        left:false,
        shot:false,
    };

    constructor(readonly car: Car)
    { }

    setControlsState(value: IControlsState) 
    {
        let state = this.state;
        state.forward = value.forward;
        state.backward = value.backward;
        state.right = value.right;
        state.left = value.left;
        state.shot = value.shot;
    }

    tick()
    {
        let car = this.car;
        let speed = car.speed;
        let angle = car.angle;
        let acseleration = 0.01 * (car.maxSpeed - Math.abs(car.speed)) / car.maxSpeed;
        
        if (this.state.forward) 
        {
            if (speed < 0) acseleration = 0.02;
            speed = Math.min(speed + acseleration, car.maxSpeed);
        }

        if (this.state.backward) 
        {
            if (speed > 0) acseleration = 0.02;
            speed = Math.max(speed - acseleration, -car.maxSpeed);
        }

        if (!this.state.forward && !this.state.backward && car.speed !== 0)
        {
            let speedDiff = 0.2 / (Math.abs(speed) || 0.2);
            speed = Math.sign(speed) * Math.max((Math.abs(speed) - acseleration), 0);
        }
            
        if (this.state.right)
            angle += Math.PI / 100 * Math.sign(speed) * Math.sqrt(Math.abs(speed*10) + 1);

        if (this.state.left)
            angle -= Math.PI / 100 * Math.sign(speed) * Math.sqrt(Math.abs(speed*10) + 1);

        let isChanging = false;    
        if (car.speed !== speed || car.angle !== angle)
        {
            car.speed = speed;
            car.angle = angle;
            isChanging = true;
        }

        isChanging = this.checkShot() || isChanging;

        if (isChanging && this.car.onChangeState)
            this.car.onChangeState();
    }

    checkShot():boolean
    {
        let now = Date.now();

        if (this.state.shot && this.shotTime < now && this.car.addVolume(-BaseObject.calcVolume(this.ballValue))) 
        {
            this.shotTime = now + this.shotInterval;
            this.car.addBall(this.ballSpeed, this.ballValue);
            return true;
        }
        return false;
    }
}