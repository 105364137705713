export interface IControlsState
{
    forward: boolean;
    backward: boolean;
    right: boolean;
    left: boolean;
    shot: boolean;
}

export class InputController
{
    readonly state: IControlsState = {
        forward: false, 
        backward: false, 
        right: false,
        left:false,
        shot:false,
    };

    constructor(readonly onChange: () => void)
    {
        window.addEventListener("keydown", (e) => this.onKeyDown(e));
        window.addEventListener("keyup", (e) => this.onKeyUp(e));
    }

    onKeyDown(e: KeyboardEvent): void
    {
        switch (e.key) 
        {
            case "ArrowUp":
                this.setProp("forward", true);
                break;
            case "ArrowDown":
                this.setProp("backward", true);
                break;
            case "ArrowRight":
                this.setProp("right", true);
                break;
            case "ArrowLeft":
                this.setProp("left", true);
                break;
            case " ":
                this.setProp("shot", true);
                break;
        }
    }

    onKeyUp(e: KeyboardEvent): void 
    {
        switch (e.key) 
        {
            case "ArrowUp":
                this.setProp("forward", false);
                break;
            case "ArrowDown":
                this.setProp("backward", false);
                break;
            case "ArrowRight":
                this.setProp("right", false);
                break;
            case "ArrowLeft":
                this.setProp("left", false);
                break;
            case " ":
                this.setProp("shot", false);
                break;
        }
    }

    private setProp(name: keyof IControlsState, value:boolean)
    {
        if (this.state[name] === value)
            return;

        this.state[name] = value;
        this.onChange();
    }

}