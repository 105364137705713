import { GameServer} from "./GameServer"
import { GameClient } from "./GameClient"

const nameInput = document.getElementById("name_input") as HTMLInputElement;
const copyClientUrlBtn = document.getElementById("copy_client_url") as HTMLButtonElement;
const clientUrlEl = document.getElementById("client_url") as HTMLElement;
const canvas = document.getElementById("canvas") as HTMLCanvasElement;
const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
const speedEl = document.getElementById("car_speed") as HTMLElement;

function resizeCanvas()
{
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
}

resizeCanvas();

const game = window.location.pathname !== "/" && window.location.pathname !== "/dev" 
    ? new GameClient(ctx, window.location.pathname.slice(1)) 
    : new GameServer(ctx);

nameInput.value = game.name;
nameInput.addEventListener("input", () => game.setName(nameInput.value));
nameInput.addEventListener("keypress", (e) => 
{
    if (e.key === "Enter")
        nameInput.blur();
});

copyClientUrlBtn.addEventListener("click", () => 
{
    let text = clientUrlEl.textContent; 
    if (text)
       navigator.clipboard.writeText(text);
});

function tick(timestamp: number)
{
    speedEl.innerText = (game.speed * 100).toFixed(2);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    game.tick();
    window.requestAnimationFrame(tick);
}

if (game instanceof GameServer)
{
    game.onSignal = (data => {
        clientUrlEl.textContent = window.location.origin + "/" + data;

        if (window.location.pathname === '/')
            window.history.pushState({"html":"","pageTitle":""},"", data);
    });
}

if (game instanceof GameClient) 
{
    clientUrlEl.style.display = "none";
    copyClientUrlBtn.style.display = "none";
} 

window.requestAnimationFrame(tick);
window.addEventListener("resize", resizeCanvas);

console.log("ready");
