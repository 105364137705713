import GameMap from "./GameMap";

export default class BaseObject
{
    static calcVolume(value: number)
    {
        return Math.PI * value ** 2;
    }

    onChangeState?: () => void = undefined;

    protected lastTickTime = Date.now();

    constructor(readonly map: GameMap,
                protected _x = 0,
                protected _y = 0,
                protected _value = 0,
                protected _angle = 0,
                protected _speed = 0
    ) {}

    get x() {return this._x;}
    get y() {return this._y;}
    get value() {return this._value;}
    
    get speed() {return this._speed;}
    set speed(value) {this._speed = value}
    
    get angle() {return this._angle;}
    set angle(value) {this._angle = value}

    tick()
    {
        let now = Date.now();
        let timediff = now - this.lastTickTime;
        this._x += timediff * this._speed * Math.cos(this._angle);
        this._y += timediff * this._speed * Math.sin(this._angle);
        this.lastTickTime = now;

        if (this._x > this.map.size - this._value)
            this._x = this.map.size - this._value
        else if (this._x < this._value)
            this._x = this._value;

        if (this._y > this.map.size - this._value)
            this._y = this.map.size - this._value
        else if (this._y < this._value)
            this._y = this._value;
    }

    intersect(body: BaseObject):boolean
    {
        return Math.sqrt((this.x-body.x)**2 + (this.y-body.y)**2) < this.value + body.value;
    }

    getVolume()
    {
        return BaseObject.calcVolume(this._value);
    }

    addVolume(volume: number)
    {
        let newVolume = this.getVolume() + volume;

        if (newVolume < 0)
            return false;
        
        this._value = Math.sqrt((newVolume) / Math.PI);
        this.signalChangeState() 
        return true;
    }

    protected signalChangeState() 
    {
        if (this.onChangeState)
            this.onChangeState();
    }
}