import BaseObject from "./BaseObject";
import Eball from "./Eball";
import GameMap from "./GameMap";

export interface ICarState
{
    x:number;
    y:number; 
    angle:number;
    speed:number;
    value:number;
    addedBalls: {speed: number, value:number}[];
    removedBalls: number[];
}

const MAX_SPEED = 0.5;
const DEF_VALUE = 50

export default class Car extends BaseObject
{
    readonly maxSpeed = MAX_SPEED;
    readonly balls: Eball[] = [];

    private addedBallsData: {speed: number, value:number}[] = [];
    private removedBallsData: number[] = [];

    constructor(readonly map: GameMap, readonly ctx: CanvasRenderingContext2D, public color: string, public name: string)
    {
        super(map, 0, 0, DEF_VALUE, 0, 0);
        this.ctx = ctx;
        this.color = color;
        this.name = name;
    }

    setState(state:ICarState)
    {
        this._x = state.x;
        this._y = state.y;
        this._value = state.value;
        this._angle = state.angle;
        this._speed = state.speed;
        state.addedBalls.forEach(data => this.createBall(data.speed, data.value));
        state.removedBalls.forEach(index => this.balls.splice(index, 1))
    }

    get state():ICarState
    {
        let addedBalls = this.addedBallsData;
        this.addedBallsData = [];
        let removedBalls = this.removedBallsData;
        this.removedBallsData = [];
        return {
            x: this._x,
            y: this._y,
            value: this._value,
            angle: this._angle,
            speed: this._speed,
            addedBalls,
            removedBalls,
        }
    }

    tick()
    {
        super.tick();

        for (let ball of this.balls) 
        {
            ball.tick();
        }
    }

    addBall(speed:number, value:number)
    {
        this.createBall(speed, value)
        this.addedBallsData.push({speed, value});
        this.signalChangeState();        
    }

    removeBall(ball: Eball)
    {
        let index = this.balls.indexOf(ball);
        this.balls.splice(index, 1);
        this.removedBallsData.push(index);
        this.signalChangeState();        
    }    

    draw()
    {
        let x = this.x + this.map.x;
        let y = this.y + this.map.y;
        
        this.drawBody(x, y);

        if (this.name) 
            this.drawName(x, y);

        for (let ball of this.balls)
        {
            ball.draw();
        }
    }
    
    private drawBody(x: number, y: number) 
    {
        let r = this.value;
        let k = 1 + Math.abs(this._speed) / this.maxSpeed;
        //let k = this._speed > r ? 1 : 1+(this.getDistance()-r)/r;
    
        let start = this._angle-0.5 * Math.PI;
        let startX = x + r * Math.cos(start);
        let startY = y + r * Math.sin(start);
    
        let end = this._angle+0.5 * Math.PI;
        let endX = x + r * Math.cos(end);
        let endY = y + r * Math.sin(end);
    
        let cp_a = 0.2 / k;
        let cp_r = 1.66 * Math.sqrt(k);
    
        let cp1 = this._angle - cp_a * Math.PI;
        let cp1X = x + cp_r * r * Math.cos(cp1);
        let cp1Y = y + cp_r * r * Math.sin(cp1);
    
        let cp2 = this._angle + cp_a * Math.PI;
        let cp2X = x + cp_r * r * Math.cos(cp2);
        let cp2Y = y + cp_r * r * Math.sin(cp2);
    
        let cpb_a = 0.8;
        let cpb_r = 1.66;

        let cpb1 = this._angle + cpb_a * Math.PI;
        let cpb1X = x + cpb_r * r * Math.cos(cpb1);
        let cpb1Y = y + cpb_r * r * Math.sin(cpb1);

        let cpb2 = this._angle - cpb_a * Math.PI;
        let cpb2X = x + cpb_r * r * Math.cos(cpb2);
        let cpb2Y = y + cpb_r * r * Math.sin(cpb2);
    
        let ctx = this.ctx;
        ctx.beginPath();
        ctx.strokeStyle = this.color;
        ctx.moveTo(startX, startY);
        ctx.bezierCurveTo(cp1X, cp1Y, cp2X, cp2Y, endX, endY);
        ctx.bezierCurveTo(cpb1X, cpb1Y, cpb2X, cpb2Y, startX, startY);
        ctx.stroke();
    }

    override addVolume(volume: number): boolean 
    {
        let result = super.addVolume(volume);

        return result;
    }

    private drawName(x: number, y: number)
    {
        let ctx = this.ctx;
        ctx.fillStyle = this.color;
        ctx.font = `${this.value/DEF_VALUE*16}px Arial`;
        ctx.textBaseline = 'middle';
        ctx.textAlign = "center";
        ctx.fillText(this.name, x, y);
    }

    private createBall(speed:number, value:number)
    {
        this.balls.push(new Eball(this, speed, value));
    }
}
